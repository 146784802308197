@import 'components';

input#id_email, input[type=password] {
  @include input();
}

h1 {
  margin-bottom: 0;
}

div.header div.outer div.header-content a.sign-in.sign-in-alternative {
  color: #666;
  text-transform: none;
  font-size: 1em;
  font-weight: 400;
  margin-top: 0.65em;
  &:hover, &:focus {
    color: black;
  }
}

div.form {
  width: 100%;
  margin-bottom: modular-scale(2, 1em, $golden);
  padding: modular-scale(-1, 1em, $golden);

  div.outer {
    padding: modular-scale(-1, 1em, $golden);
    @include outer-container;

    p.invited {
        margin-bottom: modular-scale(1, 1em, $golden);
    }

    div.signin {
      @include span-columns(7);
      @include media($tablet) {
        @include span-columns(9);
        @include omega();
      }
      @include media($mobile) {
        @include span-columns(4);
        @include omega();
      }
      div {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        input#id_email, input[type="password"] {
          @include fill-parent;
        }
      }
      div.label {
        white-space:  nowrap;
        @include span-columns(2 of 7);
        @include media($tablet) {
          @include span-columns(2 of 9);
        }
        @include media($mobile) {
          @include span-columns(4 of 4);
          @include omega();
        }
      }
      div.input {
        @include span-columns(5 of 7);
        @include omega();
        @include media($tablet) {
          @include span-columns(7 of 9);
          @include omega();
        }
        @include media($mobile) {
          @include span-columns(4 of 4);
          @include omega();
          margin-top: 0;
        }
        &.input-only {
          @include shift(2 of 7);
          @include media($tablet) {
            @include shift(2 of 9);
          }
          @include media($mobile) {
            @include shift(0);
            &.sign-in {
              text-align: center;
            }
          }
        }
      }

      p.footnote {
        @include span-columns(7 of 7);
        @include media($tablet) {
          @include span-columns(9 of 9);
        }
        @include media($mobile) {
          @include span-columns(4 of 4);
        }
      }
    }

    p.footnote {
      font-size: smaller;
    }

    div.choice {
      @include span-columns(1);
      @include media($tablet) {
        @include span-columns(9);
      }
      @include media($mobile) {
        @include span-columns(4);
      }
      position: relative;
      height: 18em;
      p {
        margin-top: 7.5em;
        background-color: white;
        padding-bottom: 0.2em;
        text-align: center;
      }
      &:after {
        content:"";
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 50%;
        border-left: 1px solid #999;
      }
      @include media($tablet or $mobile) {
        height: auto;
        p {
          margin-top: inherit;
          width: 2em;
          padding-top: 0.5em;
          margin-left: auto;
          margin-right: auto;
        }
        &:after {
          left: 0;
          right: 0;
          top: 50%;
          bottom: auto;
          border-left: none;
          border-top: 1px solid #999;
        }
      }
    }

    div.thirdparty {
      @include span-columns(4);
      @include media($tablet) {
        @include span-columns(9);
        text-align: center;
      }
      @include media($mobile) {
        @include span-columns(4);
        text-align: center;
      }
      p {
        margin-bottom: 1em;
      }
    }
  }
}
