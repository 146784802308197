html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

input, textarea {
  font-weight: 300;
  font-size: 1.25em;
  padding: 2.5px 0.8522727273em;
  border: 1px solid #999;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  display: inline-block;
}
input:disabled, textarea:disabled {
  opacity: 0.5;
  cursor: default;
}

div.input-find {
  position: relative;
  display: inline-block;
}
div.input-find input {
  padding: 0.25ex 0.75em 0.25ex 1.65em;
}
div.input-find span.ff-search {
  position: absolute;
  left: 0.6em;
  top: 1.5ex;
  pointer-events: none;
}

div.input-select {
  position: relative;
  display: inline-block;
  margin-right: 1.45em;
  margin-bottom: 1em;
  position: relative;
  display: inline-block;
}
div.input-select span.ff {
  position: absolute;
  right: 0.8522727273em;
  top: 7.5px;
  pointer-events: none;
}
div.input-select select {
  min-height: 1.8em;
  width: 100%;
  font-weight: 300;
  font-size: 1.25em;
  padding: 2.5px 1.9886363636em 2.5px 0.8522727273em;
  border: 1px solid #999;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  display: inline-block;
}
div.input-select select:invalid {
  color: #a9a9a9;
}
div.input-select select:disabled, div.input-select select:disabled + span {
  opacity: 0.5;
  cursor: default;
}

/**
* Sets width of top level container to be either default (no args to outer-container mixin - which results in a max width of 68em) or 95% of the parent available width if we are below the 68 cutoff
This means there will be a bit of margin left and right for mobile and tablet displays but crucially this should not be done with padding etc else it throws wverything else out

**/
img.avatar {
  width: 25px;
  height: 25px;
  margin-right: 0.5em;
  vertical-align: bottom;
}

input#id_email, input[type=password] {
  font-weight: 300;
  font-size: 1.25em;
  padding: 2.5px 0.8522727273em;
  border: 1px solid #999;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  display: inline-block;
}

h1 {
  margin-bottom: 0;
}

div.header div.outer div.header-content a.sign-in.sign-in-alternative {
  color: #666;
  text-transform: none;
  font-size: 1em;
  font-weight: 400;
  margin-top: 0.65em;
}
div.header div.outer div.header-content a.sign-in.sign-in-alternative:hover, div.header div.outer div.header-content a.sign-in.sign-in-alternative:focus {
  color: black;
}

div.form {
  width: 100%;
  margin-bottom: 2.617924em;
  padding: 0.6180469716em;
}
div.form div.outer {
  padding: 0.6180469716em;
  max-width: 68em;
  margin-left: auto;
  margin-right: auto;
}
div.form div.outer::after {
  clear: both;
  content: "";
  display: table;
}
div.form div.outer p.invited {
  margin-bottom: 1.618em;
}
div.form div.outer div.signin {
  float: left;
  display: block;
  margin-right: 3.287743293%;
  width: 56.9634402946%;
}
div.form div.outer div.signin:last-child {
  margin-right: 0;
}
@media screen and (max-width: 879px) {
  div.form div.outer div.signin {
    float: left;
    display: block;
    margin-right: 4.4322311852%;
    width: 100%;
    margin-right: 0;
  }
  div.form div.outer div.signin:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 559px) {
  div.form div.outer div.signin {
    float: left;
    display: block;
    margin-right: 10.5574324324%;
    width: 100%;
    margin-right: 0;
  }
  div.form div.outer div.signin:last-child {
    margin-right: 0;
  }
}
div.form div.outer div.signin div {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
div.form div.outer div.signin div input#id_email, div.form div.outer div.signin div input[type=password] {
  width: 100%;
}
div.form div.outer div.signin div.label {
  white-space: nowrap;
  float: left;
  display: block;
  margin-right: 5.7716726307%;
  width: 24.4488052638%;
}
div.form div.outer div.signin div.label:last-child {
  margin-right: 0;
}
@media screen and (max-width: 879px) {
  div.form div.outer div.signin div.label {
    float: left;
    display: block;
    margin-right: 4.4322311852%;
    width: 18.7749313004%;
  }
  div.form div.outer div.signin div.label:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 559px) {
  div.form div.outer div.signin div.label {
    float: left;
    display: block;
    margin-right: 10.5574324324%;
    width: 100%;
    margin-right: 0;
  }
  div.form div.outer div.signin div.label:last-child {
    margin-right: 0;
  }
}
div.form div.outer div.signin div.input {
  float: left;
  display: block;
  margin-right: 5.7716726307%;
  width: 69.7795221055%;
  margin-right: 0;
}
div.form div.outer div.signin div.input:last-child {
  margin-right: 0;
}
@media screen and (max-width: 879px) {
  div.form div.outer div.signin div.input {
    float: left;
    display: block;
    margin-right: 4.4322311852%;
    width: 76.7928375144%;
    margin-right: 0;
  }
  div.form div.outer div.signin div.input:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 559px) {
  div.form div.outer div.signin div.input {
    float: left;
    display: block;
    margin-right: 10.5574324324%;
    width: 100%;
    margin-right: 0;
    margin-top: 0;
  }
  div.form div.outer div.signin div.input:last-child {
    margin-right: 0;
  }
}
div.form div.outer div.signin div.input.input-only {
  margin-left: 30.2204778945%;
}
@media screen and (max-width: 879px) {
  div.form div.outer div.signin div.input.input-only {
    margin-left: 23.2071624856%;
  }
}
@media screen and (max-width: 559px) {
  div.form div.outer div.signin div.input.input-only {
    margin-left: 0%;
  }
  div.form div.outer div.signin div.input.input-only.sign-in {
    text-align: center;
  }
}
div.form div.outer div.signin p.footnote {
  float: left;
  display: block;
  margin-right: 5.7716726307%;
  width: 100%;
}
div.form div.outer div.signin p.footnote:last-child {
  margin-right: 0;
}
@media screen and (max-width: 879px) {
  div.form div.outer div.signin p.footnote {
    float: left;
    display: block;
    margin-right: 4.4322311852%;
    width: 100%;
  }
  div.form div.outer div.signin p.footnote:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 559px) {
  div.form div.outer div.signin p.footnote {
    float: left;
    display: block;
    margin-right: 10.5574324324%;
    width: 100%;
  }
  div.form div.outer div.signin p.footnote:last-child {
    margin-right: 0;
  }
}
div.form div.outer p.footnote {
  font-size: smaller;
}
div.form div.outer div.choice {
  float: left;
  display: block;
  margin-right: 3.287743293%;
  width: 5.3195686481%;
  position: relative;
  height: 18em;
}
div.form div.outer div.choice:last-child {
  margin-right: 0;
}
@media screen and (max-width: 879px) {
  div.form div.outer div.choice {
    float: left;
    display: block;
    margin-right: 4.4322311852%;
    width: 100%;
  }
  div.form div.outer div.choice:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 559px) {
  div.form div.outer div.choice {
    float: left;
    display: block;
    margin-right: 10.5574324324%;
    width: 100%;
  }
  div.form div.outer div.choice:last-child {
    margin-right: 0;
  }
}
div.form div.outer div.choice p {
  margin-top: 7.5em;
  background-color: white;
  padding-bottom: 0.2em;
  text-align: center;
}
div.form div.outer div.choice:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 50%;
  border-left: 1px solid #999;
}
@media screen and (max-width: 879px) {
  div.form div.outer div.choice {
    height: auto;
  }
  div.form div.outer div.choice p {
    margin-top: inherit;
    width: 2em;
    padding-top: 0.5em;
    margin-left: auto;
    margin-right: auto;
  }
  div.form div.outer div.choice:after {
    left: 0;
    right: 0;
    top: 50%;
    bottom: auto;
    border-left: none;
    border-top: 1px solid #999;
  }
}
div.form div.outer div.thirdparty {
  float: left;
  display: block;
  margin-right: 3.287743293%;
  width: 31.1415044713%;
}
div.form div.outer div.thirdparty:last-child {
  margin-right: 0;
}
@media screen and (max-width: 879px) {
  div.form div.outer div.thirdparty {
    float: left;
    display: block;
    margin-right: 4.4322311852%;
    width: 100%;
    text-align: center;
  }
  div.form div.outer div.thirdparty:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 559px) {
  div.form div.outer div.thirdparty {
    float: left;
    display: block;
    margin-right: 10.5574324324%;
    width: 100%;
    text-align: center;
  }
  div.form div.outer div.thirdparty:last-child {
    margin-right: 0;
  }
}
div.form div.outer div.thirdparty p {
  margin-bottom: 1em;
}